import { Stack } from '@ccs-dip/common/Stack';
import { IOptionValue, IComponent } from '@ccs-dip/common/types/formio-types';
import { getHtmlContentComponent, getPanelComponent } from '../generateComponentJson';

//===============================================
// private variables
//===============================================

const p_style = 'display: flex; width: 100%; justify-content: space-between;';

// used for select or radio components
const getLabelByValue = (valuesSchema: IOptionValue[], value: string | number) => {
  for (let index = 0; index < valuesSchema.length; index++) {
    const valueSchema = valuesSchema[index];
    if (valueSchema && valueSchema.value.toString() === value.toString()) {
      return valueSchema.label;
    }
  }
  return value;
};

// wrapper function for getting the value
const getValueAsLabel = (instance: any): string => {
  const type = instance.component.type;
  const value = instance.dataValue;
  if (type === 'select' || type === 'radio') {
    return type === 'select'
      ? instance.t(getLabelByValue(instance.component.data.values, value))
      : instance.t(getLabelByValue(instance.component.values, value));
  }
  return value;
};

const createContentAndFlush = (instances: any[], key: string) => {
  const html_content = instances
    .map(
      (instance) =>
        `<p style="${p_style}">
          <span><strong>${instance.name}</strong></span>
          <span><i>${getValueAsLabel(instance)}</i></span>
        </p>`
    )
    .join('<br>');

  instances.length = 0;
  return getHtmlContentComponent(`<p>${html_content}</p>`, key);
};

const isEmptySummary = (component: IComponent) => {
  const type = component.type;
  if (type === 'panel' || type === 'well') return (component.components?.length ?? 0) === 0;
  else if (type === 'content') return component.html === undefined || component.html === '<p></p>';
  return true;
};

//===============================================
// SummaryPanelBuilder Class
//===============================================

export class SummaryPanelBuilder {
  private context_stack: Stack<IComponent>;
  private root_panel: IComponent;
  private current_panel: IComponent;
  private sis_enabled: any[]; // Show In Summary enabled components

  constructor(title: string, key: string) {
    const panel = getPanelComponent(title, key, 'info', []);
    this.context_stack = new Stack<IComponent>();
    this.context_stack.push(panel);
    this.root_panel = panel;
    this.current_panel = panel;
    this.sis_enabled = [];
  }

  private popPanelContext() {
    this.context_stack.pop();

    const context = this.context_stack.peek();

    if (context) {
      const panel = this.current_panel;
      this.current_panel = context;
      this.pushInPanel(panel);
    }
  }

  private pushPanelContext(title: string, key: string) {
    const panel = getPanelComponent(title, key, 'info', []);
    this.context_stack.push(panel);
    this.current_panel = panel;
  }

  private pushInPanel(component: IComponent) {
    if (!isEmptySummary(component)) {
      this.current_panel.components?.push(component);
    }
  }

  private processContainer(instance: any) {
    const components: any[] = instance.components;
    for (const component of components) {
      this.processComponent(component);
    }
  }

  private createSummary() {
    const key = `html_content-${this.current_panel.components?.length ?? 0}`;
    const html_content = createContentAndFlush(this.sis_enabled, key);
    this.pushInPanel(html_content);
  }

  private entrySubForm(instance: any) {
    this.createSummary();
    this.pushPanelContext(instance.form.title, `nested-${instance.form.key}`);
  }

  private exitSubForm(_instance: any) {
    this.createSummary();
    this.popPanelContext();
  }

  private processSubForm(instance: any) {
    const subForm = instance.subForm;

    this.entrySubForm(subForm);
    this.processComponent(subForm);
    this.exitSubForm(subForm);
  }

  private processComponent(instance: any) {
    if (instance.components) {
      this.processContainer(instance);
    } else if (instance.subForm) {
      this.processSubForm(instance);
    } else if (instance.component.showInSummary === true) {
      this.sis_enabled.push(instance);
    }
  }

  private process(instance: any) {
    this.processComponent(instance);
    this.createSummary();
  }

  public getSummary(instance: any) {
    this.process(instance);
    return isEmptySummary(this.root_panel) ? undefined : this.root_panel;
  }
}
