import { useTheme } from '@mui/material/styles';

function IconVehicle(props: any) {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '20'}
      height={props.height || '20'}
      viewBox="0 0 640 512"
      aria-labelledby="icon-vehicle-title"
      role="img"
    >
      <title id="icon-vehicle-title">Vehicle Icon</title>
      <defs>
        <linearGradient id="vehicleGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: theme.palette.primary.main, stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: theme.palette.secondary.main, stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <path fill="none" d="M0 0h640v512H0z" data-name="background" />
      <path
        fill="url(#vehicleGradient)"
        d="M280 32c-13.3 0-24 10.7-24 24s10.7 24 24 24h57.7l16.4 30.3L256 192l-45.3-45.3c-12-12-28.3-18.7-45.3-18.7H64c-17.7 0-32 14.3-32 32v32h96c88.4 0 160 71.6 160 160 0 11-1.1 21.7-3.2 32h70.4c-2.1-10.3-3.2-21-3.2-32 0-52.2 25-98.6 63.7-127.8l15.4 28.6c-22.1 22.5-40.5 58.2-40.5 98.2 0 70.7 57.3 128 128 128s128-57.3 128-128-57.3-128-128-128c-13.5 0-26.5 2.1-38.7 6L418.2 128h61.8c17.7 0 32-14.3 32-32v-32c0-17.7-14.3-32-32-32h-20.4c-7.5 0-14.7 2.6-20.5 7.4l-46.6 41.9-14-26c-7-12.9-20.5-21-35.2-21h-35.3zm182.7 279.2l28.2 52.2c6.3 11.7 20.9 16 32.5 9.7s16-20.9 9.7-32.5l-28.2-52.2c2.3-.3 4.7-.4 7.1-.4 35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64c0-15.5 5.5-29.7 14.7-40.8zm-275.4 64h66.4C242.5 268.8 190.5 224 128 224 57.3 224 0 281.3 0 352s57.3 128 128 128c62.5 0 114.5-44.8 125.8-104h-66.4c-9.5 23.5-32.5 40-59.3 40-35.3 0-64-28.7-64-64s28.7-64 64-64c26.9 0 49.9 16.5 59.3 40zm0 8a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"
        data-name="vehicle-path"
      />
    </svg>
  );
}

export default IconVehicle;
