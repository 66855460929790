

const IconFiles = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="252" height="183" viewBox="0 0 252 183" fill="none">
    <g clipPath="url(#clip0_2097_113557)">
      <path d="M179.7 182.2H79.2C74.4 182.2 71.2 178.3 72.1 173.5L82.1 118.2C83 113.4 87.5 109.5 92.3 109.5H192.8C197.6 109.5 200.8 113.4 199.9 118.2L189.9 173.5C189 178.3 184.4 182.2 179.7 182.2Z" fill="url(#paint0_linear_2097_113557)" />
      <path d="M188.235 21.5564L147.981 98.0117L211.782 131.603L252.036 55.1478L188.235 21.5564Z" fill="#C8C6C4" />
      <path d="M190.003 27.5341L159.859 84.7871L216.05 114.372L246.194 57.1187L190.003 27.5341Z" fill="#EDEBE9" />
      <path d="M208.6 47.0004C203.8 45.5004 198.1 48.9004 195.9 54.7004C194.1 59.7004 198.4 63.4004 196.4 67.5004C194.5 71.6004 188.3 71.8004 187.9 77.1004C187.5 82.4004 190.2 84.4004 186.8 90.5004L193.2 93.9004C193.2 93.9004 193.2 89.8004 196 87.9004C198.8 86.0004 205.3 82.6004 203.8 77.8004C202.4 72.9004 205.3 69.5004 207 68.4004C208.7 67.3004 212 63.7004 213.8 59.6004C215.7 55.4004 213.9 48.7004 208.6 47.0004Z" fill="#E8D441" />
      <path d="M214.8 62.0997C219.3 64.6997 219 70.7997 216 74.6997C213.5 77.9997 208.6 76.5997 206.8 79.4997C205 82.4997 208.1 87.8997 204.2 89.9997C200.3 92.0997 197.5 91.1997 195.8 95.4997L190 92.3997C190 92.3997 192.6 90.7997 192.1 88.0997C191.5 85.3997 191.4 79.2997 195.6 78.3997C199.8 77.3997 200.7 73.9997 200.6 72.3997C200.5 70.7997 200.2 67.3997 203 64.0997C205.8 60.7997 211.9 60.4997 214.8 62.0997Z" fill="white" />
      <path d="M194.4 92.0996C194.4 92.0996 205.6 72.1996 211 67.0996" stroke="#E8D441" strokeWidth="1.7169" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M201.9 95.5L185.3 86.6999C184.6 86.2999 183.6 86.7999 183.5 87.6999L182.1 96.4L195.8 103.6L202.2 97.5C202.8 97 202.7 95.9 201.9 95.5Z" fill="#B3B0AD" />
      <path d="M93.3 0L180.1 4.6L174.2 116.4L87.4 111.8L93.3 0Z" fill="url(#paint1_linear_2097_113557)" />
      <path d="M154 23.6C153.7 23.1 153.1 22.8 152.5 22.9L142.4 24.2C141.9 24.3 141.4 24.6 141.2 25.1C141 25.6 141 26.2 141.3 26.7L143 29L132 36L126.5 29.1L110.2 39.5L112.4 42.9L125.6 34.5L131.1 41.4L145.4 32.3L147.3 34.8C147.6 35.2 148 35.5 148.5 35.5C148.6 35.5 148.6 35.5 148.7 35.5C149.3 35.4 149.7 35.1 149.9 34.6L154 25C154.3 24.5 154.3 24 154 23.6Z" fill="#FAE969" />
      <path d="M101.5 86.1004L101.8 81.4004L159.5 84.4004L161.3 36.9004L166 37.2004L163.9 89.4004L101.5 86.1004Z" fill="#E1DFDD" />
      <path d="M143.9 45.5L156.1 46.2L154.3 79.2L142.1 78.6L143.9 45.5Z" fill="#E1DFDD" />
      <path d="M125.3 51.7002L137.5 52.4002L136.1 78.2002L123.9 77.5002L125.3 51.7002Z" fill="#E1DFDD" />
      <path d="M106.4 62.9004L118.6 63.5004L117.8 77.2004L105.6 76.6004L106.4 62.9004Z" fill="#E1DFDD" />
      <path d="M90.5 42.3998L116.3 118.9L34 146.6L0 45.7998L57.9 26.2998L90.5 42.3998Z" fill="#E2E2F6" />
      <path d="M90.5 42.3998L66.2 50.5998L57.9 26.2998L90.5 42.3998Z" fill="#BDBDE6" />
      <path d="M81.9381 63.7211L50.4844 74.3418L52.0519 78.9841L83.5056 68.3634L81.9381 63.7211Z" fill="#C3C3E1" />
      <path d="M85.9202 75.6124L31.9184 93.8467L33.4859 98.4889L87.4877 80.2546L85.9202 75.6124Z" fill="#C3C3E1" />
      <path d="M89.8177 87.4259L35.8159 105.66L37.3834 110.302L91.3852 92.0681L89.8177 87.4259Z" fill="#C3C3E1" />
      <path d="M77.9397 51.9067L46.4861 62.5273L48.0536 67.1696L79.5072 56.5489L77.9397 51.9067Z" fill="#C3C3E1" />
      <path opacity="0.8" d="M40.4 64.5996L45.9 81.0996L29.5 86.4996L24 70.1996L40.4 64.5996Z" fill="#E8D441" />
      <path d="M179.7 182.2H79.2C74.4 182.2 69.9 178.3 69.1 173.6L59.9 118.4C59.1 113.6 62.3 109.8 67.1 109.8H167.6C172.4 109.8 176.9 113.7 177.7 118.4L186.9 173.6C187.7 178.4 184.4 182.2 179.7 182.2Z" fill="#A6A7DC" />
      <path d="M89.2 114.4L60.1 119.9L57.8 106.1C57 101.5 60.1 97.7998 64.7 97.7998H78.1C82.7 97.7998 87 101.5 87.8 106.1L89.2 114.4Z" fill="#A6A7DC" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_2097_113557" x1="136.014" y1="187.152" x2="136.014" y2="115.952" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6264A7" />
        <stop offset="1" stopColor="#8B8CC7" />
      </linearGradient>
      <linearGradient id="paint1_linear_2097_113557" x1="89.5923" y1="75.069" x2="119.853" y2="63.4989" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E1DFDD" />
        <stop offset="0.2108" stopColor="#EAE8E7" />
        <stop offset="0.62" stopColor="#F6F5F4" />
        <stop offset="1" stopColor="#FAF9F8" />
      </linearGradient>
      <clipPath id="clip0_2097_113557">
        <rect width="252" height="182.2" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconFiles;
