import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button } from '@mui/material';
import { IThemeConfig } from '@ccs-dip/common/types/app-config';
import { useTranslation } from 'react-i18next';

interface ThemeConfirmationDialogProps {
  open: boolean;
  themeToApply: IThemeConfig | null;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ThemeConfirmationDialog: React.FC<ThemeConfirmationDialogProps> = ({
  open,
  themeToApply,
  onConfirm,
  onCancel
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{t('confirmation')}</DialogTitle>
      <DialogContent>
        <Typography>{t('confirmApplyTheme', { themeName: themeToApply?.title })}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color='primary'>
          {t('cancel')}
        </Button>
        <Button onClick={onConfirm} color='secondary'>
          {t('apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
