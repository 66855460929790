import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import EntityListView from '../EntityListView/EntityListView';
import { IClaimListView } from 'entities/Claim/IClaim';
import { Box, Chip, alpha } from '@mui/material';
import IconInsuranceCar2 from 'component/atoms/Icons/IconInsuranceCar2';
import { useTranslation } from 'react-i18next';
import i18n from 'config/lang/i18n';
import IconHours from 'component/atoms/Icons/IconHours';

//===============================================
// private variables
//===============================================

//===============================================
// Component props interface
//===============================================

interface IClaimsListViewProps {
  rows: IClaimListView[];
}

//===============================================
// Component render function
//===============================================nameofInsurer

const ClaimsListView = (props: IClaimsListViewProps) => {
  const { t } = useTranslation<string>();
  const { rows } = props;

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        valueGetter: (_value: string, row: IClaimListView) => row.id
      },
      {
        field: 'ClaimType',
        headerName: t(''),
        type: 'string',
        flex: 0,
        renderCell: () => (
          <>
            <Box sx={{ pr: 2 }}>
              <IconInsuranceCar2></IconInsuranceCar2>
            </Box>
          </>
        )
      },
      {
        field: 'Claims',
        headerName: t('ClaimNumber'),
        type: 'string',
        flex: 1,
        valueGetter: (_value: string, row: IClaimListView) => row.CompanysClaimNumber
      },
      {
        field: 'ClaimNumber',
        headerName: t('internalClaimNumber'),
        type: 'string',
        flex: 1,
        valueGetter: (_value: string, row: IClaimListView) => row.ClaimNumber
      },
      {
        field: 'PolicyNumber',
        headerName: t('policyNumber'),
        type: 'string',
        flex: 1,
        valueGetter: (_value: string, row: IClaimListView) => row.PolicyNumber
      },
      {
        field: 'TimeRegisteration',
        headerName: t('timeRegistration'),
        type: 'string',
        flex: 1,
        valueGetter: (_value: string, row: IClaimListView) => row.LossTime,
        renderCell: (params) => (
          <>
            <Box sx={{ pr: 2 }}>
              <IconHours></IconHours>
            </Box>
            {params.row.LossTime}
          </>
        )
      },
      {
        field: 'ClaimStatusCd',
        headerName: t('status'),
        type: 'string',
        flex: 1,
        valueGetter: (_value: string, row: IClaimListView) => row.ClaimStatusCd,
        renderCell: (params) => (
          <>
            <Box sx={{ pr: 2 }}>
              {/* {params.row.ClaimStatusCd === 'O' ? ( */}
              <Chip
                sx={{
                  background: (theme) => alpha(theme.palette.secondary.main, 0.15),
                  color: (theme) => theme.palette.secondary.main
                }}
                label={params.row.ClaimStatusCd}
                color='secondary'
                variant='filled'
              />
              {/* ) : (
                <Chip
                  sx={{
                    background: (theme) => alpha(theme.palette.secondary.main, 0.15),
                    color: (theme) => theme.palette.secondary.main
                  }}
                  label={t('done')}
                  color='secondary'
                  variant='filled'
                />
              )} */}
            </Box>
          </>
        )
      },
      {
        field: 'ReportedDt',
        headerName: t('claimsReportedDt'),
        type: 'string',
        flex: 1,
        valueGetter: (_value: string, row: IClaimListView) => row.ClaimsReported[0]?.ReportedDt
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language]
  );

  return (
    <EntityListView
      id='ClaimsListView'
      columns={columns}
      rows={rows}
      getId={(row) => {
        return row.ClaimNumber;
      }}
      briefViewType='ClaimBriefView'
    />
  );
};

export default ClaimsListView;
