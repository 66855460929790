import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import EntityListView from '../EntityListView/EntityListView';
import { IPolicy } from 'entities/Policy/IPolicy';
import { Box } from '@mui/material';
import IconInsuranceCar2 from 'component/atoms/Icons/IconInsuranceCar2';
import { useTranslation } from 'react-i18next';
import i18n from 'config/lang/i18n';

//===============================================
// private variables
//===============================================

//===============================================
// Component props interface
//===============================================

interface IPolicyListViewProps {
  rows: IPolicy[];
}

// Add computed field to the rows
const addPolicyStatusToRows = (rows: IPolicy[], t: (key: string) => string): IPolicy[] => {
  return rows.map((row) => ({
    ...row,
    PolicyStatus: row.PolicyVersion === 'W' ? t('waiting') : t('active')
  }));
};

//===============================================
// Component render function
//===============================================nameofInsurer

const PolicyListView = (props: IPolicyListViewProps) => {
  const { t } = useTranslation<string>();
  const { rows } = props;

  // Compute policy status for each row
  const rowsWithStatus = useMemo(() => addPolicyStatusToRows(rows, t), [rows, t]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        valueGetter: (_value: string, row: IPolicy) => row.id
      },
      {
        field: 'PolicyType',
        headerName: t(''),
        type: 'string',
        flex: 0,
        renderCell: () => (
          <>
            <Box sx={{ pr: 2 }}>
              <IconInsuranceCar2></IconInsuranceCar2>
            </Box>
          </>
        )
      },
      {
        field: 'LeadInsurerName',
        headerName: t('nameofInsurer'),
        type: 'string',
        flex: 1,
        valueGetter: (_value: string, row: IPolicy) => row.LeadInsurerName
      },
      {
        field: 'PolicyNumber',
        headerName: t('internalPolicyNumber'),
        type: 'string',
        flex: 1,
        valueGetter: (_value: string, row: IPolicy) => row.PolicyNumber
      },
      {
        field: 'CompanysPolicyNumber',
        headerName: t('policyNumber'),
        type: 'string',
        flex: 1,
        valueGetter: (_value: string, row: IPolicy) => row.CompanysPolicyNumber
      },
      {
        field: 'LOBCd',
        headerName: t('LineOfBusiness'),
        type: 'string',
        flex: 1,
        valueGetter: (_value: string, row: IPolicy) => row.LOBCd
      },
      {
        field: 'PolicyVersion',
        headerName: t('policyStatus'),
        type: 'string',
        flex: 1,
        valueGetter: (_value: string, row: IPolicy) => row.PolicyVersion
      },
      { field: 'OriginalPolicyInceptionDt', headerName: t('originalPolicyInceptionDt'), type: 'string', flex: 1 }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language]
  );

  return (
    <EntityListView
      id='PolicyListView'
      columns={columns}
      rows={rowsWithStatus}
      columnVisibilityModel={{
        PolicyNumber: false,
        LOBCd: false
      }}
      getId={(row) => row.PolicyNumber}
      briefViewType='PolicyBriefView'
    />
  );
};

export default PolicyListView;
