import { ReactNode, useEffect, useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { loaderService } from 'entities/Loader/LoaderService';

interface LoaderProviderProps {
  children: ReactNode | undefined;
}

const LoaderProvider = ({ children }: LoaderProviderProps) => {
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);

  useEffect(() => {
    // Subscribe to loaderService to track the openBackdrop state
    const listener = (isOpen: boolean) => {
      setOpenBackdrop(isOpen);
    };

    loaderService.subscribe(listener);

    return () => {
      loaderService.unsubscribe(listener);
    };
  }, []);

  return (
    <>
      {children}
      <Backdrop sx={{ color: '#525ce0', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openBackdrop}>
        <CircularProgress color='primary' />
      </Backdrop>
    </>
  );
};
export const useLoader = () => {
  return {
    loader: loaderService.loader.bind(loaderService),
  };
};

export { LoaderProvider };
