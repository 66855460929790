import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import  CustomDataGridToolbar, { DataGridProSx } from 'utils/CustomDataGridToolbar';
import { generateOverviewUrl, getOverviewIconData } from 'utils/Utils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { displayForm, iconWrapper, OverviewLink, titleText } from './InsuranceOverviewStyles';
import { InsuranceOverviewDataGridProps } from './InsuranceOverviewTypes';
import DataGridIcons from './InsuranceOverviewIcons';

const InsuranceOverviewDataGrid: React.FC<InsuranceOverviewDataGridProps> = ({  rows, partynumber, handleEdit, handleDelete }) => {

  const { t } = useTranslation();
  const renderNameCell = (params: any) => (
    <OverviewLink to={generateOverviewUrl(params.row.url, partynumber)||''}>
      <Box sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
      {getOverviewIconData(params.row.icon)?.icon}
      <Box title={params.row.title} sx={titleText}>
        {params.row.title}
      </Box>
      </Box>
    </OverviewLink>
  );

  const renderDescription = (params: any) => (
    <>
      <Typography component='span' sx={displayForm}>
        {t(params.row.description)}
      </Typography>
    </>
  );

  const handleCopyUrl = (url:string) => {
    navigator.clipboard.writeText(url);
  };

  const renderUrlCell = (params: any) => {
    return (
      <>
        <IconButton onClick={()=>{handleCopyUrl(params.row.url)}} color='primary' aria-label='copy URL' sx={{ ml: 1, color:(theme:any)=>theme.palette.secondary.main }}>
            <ContentCopyIcon sx={{fontSize:'22px'}} />
          </IconButton>
        <Typography component='span' sx={displayForm}>
          {t(params.row.url)}
        </Typography>
      </>
    );
  };

  const renderActionCell = (params: any) => {
    const rowIndex = rows.findIndex(row => row.title === params.row.title);
    return (
      <>
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleEdit(rowIndex); 
          }}
          color='primary'
          aria-label='edit'>
          <Tooltip title={t('edit')}>
            <DataGridIcons.DriveFileRenameOutlineIcon sx={iconWrapper} />
          </Tooltip>
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleDelete(rowIndex); 
          }}
          color='primary'
          aria-label='delete'>
          <Tooltip title={t('delete')}>
            <DataGridIcons.DeleteOutlineIcon sx={iconWrapper} />
          </Tooltip>
        </IconButton>
      </>
    );
  };
  
  
  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: t('title'),
      flex: 1,
      renderCell: renderNameCell
    },
    {
      field: 'description',
      headerName: t('description'),
      flex: 2,  
      renderCell: renderDescription
    },
    {
      field: 'url',
      headerName: t('url'),
      flex: 1,
      renderCell: renderUrlCell
    },
    {
      field: 'delete',
      headerName: t('action'),
      flex: 0.5,  
      disableColumnMenu: true,
      renderCell: renderActionCell
    }
  ];
  

  return (
    <Box sx={{ height: 'calc(100vh - 100px)', width: '100%',p: 2 }}>
      <Box sx={{ py: 2, height: '100%', width: '100%' }}>
        <DataGridPro
          sx={DataGridProSx}
          rows={rows}
          columns={columns}
          columnHeaderHeight={36}
          editMode='row'
          getRowId={(row: any) => row.title}
          disableColumnPinning
          pagination
          initialState={{
            sorting: {
              sortModel: [{ field: 'title', sort: 'asc' }]
            },
            pagination: { paginationModel: { pageSize: 10 } }
          }}
          pageSizeOptions={[
            10,
            20,
            40
          ]}
          slots={{ toolbar: CustomDataGridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              title: t('insuranceOverview')
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default InsuranceOverviewDataGrid;


