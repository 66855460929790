import { useSnackBar } from 'context/Snackbar/Snackbar';
import { useTranslation } from 'react-i18next';

const useSwaggerNotification = () => {
  const { showSnackBar } = useSnackBar();
  const { t } = useTranslation();

  const swaggerNotification = {
    swaggerUpdate: () => {
      showSnackBar(t('swaggerUpdate'), 'success');
    },
    swaggerError: () => {
      showSnackBar(t('swaggerError'), 'error');
    },
    unableDelete: () => {
      showSnackBar(t('unableDelete'), 'error');
    },
    noFileSelected: () => {
      showSnackBar(t('noFileSelected'), 'error');
    },
    swaggerAcceptedFiles: () => {
      showSnackBar(t('swaggerAcceptedFiles'), 'error');
    },
    invalidStructure: () => {
      showSnackBar(t('invalidStructure'), 'error');
    },
    swaggerUploadSuccess: () => {
      showSnackBar(t('swaggerUploadSuccess'), 'success');
    },
    uploadError: () => {
      showSnackBar(t('uploadError'), 'error');
    },
    fileSizeError: () => {
      showSnackBar(t('fileSizeError'), 'error');
    }
  };

  return { swaggerNotification };
};

export default useSwaggerNotification;
