import { useEffect, useState } from 'react';
import { createTheme, Theme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTheme } from 'store/theme/themeSlice';
import AppConfigService from 'entities/AppConfig/AppConfigService';
import { IAppConfig, IThemeConfig } from '@ccs-dip/common/types/app-config';
import { State } from 'store/store';

const useThemeConfig = () => {
  const dispatch = useDispatch();
  const [theme, setTheme] = useState<Theme>(createTheme());  // Default theme
  const defaultTheme = useSelector((state: State) => state.themeConfig.defaultTheme);
  const currentTheme = useSelector((state: State) => state.themeConfig.currentTheme);
  const darkTheme = useSelector((state: State) => state?.themeConfig?.darkTheme);

  const applyDarkTheme=(selectedTheme:IThemeConfig)=>{
    const combinedTheme: IThemeConfig = { ...selectedTheme, theme: { ...selectedTheme.theme } };
    if(selectedTheme.isDark){
    combinedTheme.theme = {
      ...darkTheme.theme,
      palette: {
        ...darkTheme.theme.palette,
        primary: {
          ...darkTheme.theme.palette.primary,
          main: selectedTheme.theme.palette.primary.main, // Override primary main color
        },
        secondary: {
          ...darkTheme.theme.palette.secondary,
          main: selectedTheme.theme.palette.secondary.main, // Override secondary main color
        }
      }
    };
  }
    return combinedTheme
  }


  const applyTheme = (themeConfig:IThemeConfig) => {
    const root:any= document.querySelector(':root');
    const updatedThemeConfig=applyDarkTheme(themeConfig);
    const newTheme = createTheme(updatedThemeConfig.theme);
    setTheme(newTheme);
    root?.style?.setProperty('--theme-color', newTheme.palette.secondary.main);
    root?.style?.setProperty('--text-primary', newTheme.palette.text.primary);
    root?.style?.setProperty('--text-secondary', newTheme.palette.text.secondary);
    root?.style?.setProperty('--background-default', newTheme.palette.background.default);
    root?.style?.setProperty('--background-paper', newTheme.palette.background.paper);
  };

  useEffect(() => {
    // Apply the current theme from the Redux store
    if (currentTheme) {
      applyTheme(currentTheme);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTheme]);

  useEffect(() => {
    const appConfigService = new AppConfigService();
    appConfigService.get().then((response: IAppConfig) => {
      const selectedTheme = response.themeconfigs?.find(
        (itm: IThemeConfig) => itm.isSelected
      );
      if (selectedTheme) {
        applyTheme(selectedTheme);
        dispatch(setCurrentTheme(selectedTheme));
      } else {
        applyTheme(defaultTheme);
        dispatch(setCurrentTheme(defaultTheme));
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, defaultTheme]);

  return theme;
};

export default useThemeConfig;
