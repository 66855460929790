import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IBriefViewDrawerState {
  open: boolean;
  briefViewComponent: JSX.Element | null;
}

const initialState: IBriefViewDrawerState = {
  open: false,
  briefViewComponent: null
};

export const briefViewDrawerSlice = createSlice({
  name: 'briefViewDrawer',
  initialState,
  reducers: {
    setOpenBriefView(state, action: PayloadAction<boolean>) {
      state.open = action.payload;
      // If the drawer is closed, reset the component
      if (!action.payload) {
        state.briefViewComponent = null;
      }
    },
    setBriefView(state, action: PayloadAction<JSX.Element | null>) {
      state.briefViewComponent = action.payload;
    }
  }
});

export const { setOpenBriefView, setBriefView } = briefViewDrawerSlice.actions;
export default briefViewDrawerSlice.reducer;
