import { LocalizationProvider, PickerValidDate } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { keyGenerator } from 'utils/Utils';
import FormControl from '@mui/material/FormControl';
import { InputLable } from '../InputLabel/InputLabel';
import { DatePickerField } from './DatePickerStyle';

const randomKey = keyGenerator();

interface IDatepickerProps {
  label?: string;
}

export default function Datepickercalendar(props: IDatepickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl sx={{ mt: 2 }} variant='standard'>
        <InputLable shrink htmlFor={`${randomKey}MuiInputfield`} sx={{ typography: 'body2' }}>
          {props.label}
        </InputLable>

        <DatePickerField
          slotProps={{
            textField: { size: 'small' },
            leftArrowIcon: {
              className: 'arrowStyle'
            },
            rightArrowIcon: {
              className: 'arrowStyle'
            }
          }}
          dayOfWeekFormatter={(day: PickerValidDate) => `${day.format('dd')}`}
        />
      </FormControl>
    </LocalizationProvider>
  );
}
