import { useTheme } from "@mui/material";

const  IconPackage= () =>{
  const theme = useTheme();
  return(
    <svg xmlns="http://www.w3.org/2000/svg"  width='25px' height='25px' viewBox="0 0 576 512" >
      <defs>
        <linearGradient id="duotoneGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: theme.palette.primary.main, stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor:  theme.palette.secondary.main, stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <path
        d="M0 48C0 21.5 21.5 0 48 0h288c26.5 0 48 21.5 48 48v159l-42.4 17H272c-8.8 0-16 7.2-16 16v64c0 .9.1 1.7.2 2.6 2.3 58.1 24.1 144.8 98.7 201.5-5.8 2.5-12.2 3.9-18.9 3.9h-96v-80c0-26.5-21.5-48-48-48s-48 21.5-48 48v80H48c-26.5 0-48-21.5-48-48V48zm80 176c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16H80zm80 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16zM64 112v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm112-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16h-32zm80 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16zm167.1 113.7c5.7-2.3 12.1-2.3 17.8 0l120 48c9.1 3.7 15.1 12.5 15.1 22.3 0 63.3-25.9 168.8-134.8 214.2-5.9 2.5-12.6 2.5-18.5 0C313.9 464.8 288 359.3 288 296c0-9.8 6-18.6 15.1-22.3l120-48zM527.4 312 432 273.8v187.8c68.2-33 91.5-99 95.4-149.7z"
        fill="url(#duotoneGradient)"
      />
    </svg>
  )};
  
  export default IconPackage;
  