import { useSnackBar } from 'context/Snackbar/Snackbar';
import { useTranslation } from 'react-i18next';

const useInsuranceNotification = () => {
  const { showSnackBar } = useSnackBar();
  const { t } = useTranslation();

  const insuranceOverviewNotification = {
    formValidationError: () => {
      showSnackBar(t('requiredField'), 'error');
    },
    savedSuccessfully: () => {
      showSnackBar(t('saveMessage'), 'success');
    },
    saveFailed:()=>{
      showSnackBar(t('saveFailed'), 'error');
    }
  };

  return { insuranceOverviewNotification };
};

export default useInsuranceNotification;
