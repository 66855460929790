import { useEffect, useState } from 'react';
import FormioService from 'entities/Formio/FormioService';
import { Alert, Box, Card, CardContent, CardActions, Typography, IconButton, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IFormInfo } from '@ccs-dip/common/types/formio-types';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DevicesFoldIcon from '@mui/icons-material/DevicesFold';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ListAltIcon from '@mui/icons-material/ListAlt';

const FormBuilderForms: React.FC = () => {
  const [formio, setFormio] = useState<IFormInfo[]>([]);
  const service = new FormioService();
  const { t } = useTranslation<string>();

  useEffect(() => {
    service.getAllForm().then((forms: IFormInfo[]) => {
      setFormio(forms.filter((item) => item.savedOption === 'Publish'));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ p: 5 }}>
      <Typography sx={{ fontSize: '20px', mb: 2 }} gutterBottom>
        <ListAltIcon sx={{ mr: 1 }} />
        Forms/Wizards
      </Typography>

      {formio.length ? (
        <Box display="flex" flexWrap="wrap" gap={3}>
          {formio.map((form: IFormInfo, index: number) => (
            <Card key={index} sx={{ minWidth: 275, maxWidth: 300,zIndex:1000,position:'relative', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', '&:hover': { boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' } }}>
              <Link to={`/formrenderer/${form.key}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <CardContent>
                  <Tooltip title={t('formTypeTooltip', { defaultValue: 'Form type' })}>
                    <Box display="flex" alignItems="center" gap={1}>
                      {form.display === 'wizard' ? (
                        <VideoLabelIcon fontSize="small" color="secondary" />
                      ) : (
                        <DescriptionOutlinedIcon fontSize="small" color="secondary" />
                      )}
                      <Typography sx={{ fontSize: '16px' }} component="div" color="text.primary">
                        {form.title}
                      </Typography>
                    </Box>
                  </Tooltip>

                  <Tooltip title={t('productIdTooltip', { defaultValue: 'Product ID' })}>
                    <Box display="flex" alignItems="center" gap={1} sx={{ mt: 1 }}>
                      <DevicesFoldIcon fontSize="small" color="secondary" />
                      <Typography variant="body2" color="text.secondary">
                        {form.productId || t('noProductid')}
                      </Typography>
                    </Box>
                  </Tooltip>
                </CardContent>
              </Link>
              
              <CardActions>
                <Link to={`/settings/3`} style={{ textDecoration: 'none' }}>
                  <Tooltip title={t('editFormTooltip', { defaultValue: 'Edit form' })}>
                    <IconButton color="primary" size="small">
                      <BorderColorIcon sx={{ color: (theme) => theme.palette.secondary.main }} fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Link>
              </CardActions>
            </Card>
          ))}
        </Box>
      ) : (
        <Box sx={{ py: 2 }}>
          <Alert severity="info">{t('noFormsAvailable')}</Alert>
        </Box>
      )}
    </Box>
  );
};

export default FormBuilderForms;
