import { useTheme } from '@mui/material/styles';

function IconTruck(props:any) {
  const theme = useTheme();
  
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '20'}
      height={props.height || '20'}
      viewBox="0 0 640 512"
      aria-labelledby="icon-truck-title"
      role="img"
    >
      <title id="icon-truck-title">Truck Icon</title>
      <defs>
        <linearGradient id="truckGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: theme.palette.primary.main, stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: theme.palette.secondary.main, stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <path fill="none" d="M0 0h640v512H0z" data-name="background" />
      <path
        fill="url(#truckGradient)"
        d="M48 0C21.5 0 0 21.5 0 48L0 368c0 26.5 21.5 48 48 48h16c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32v-64h-32v-18.7c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H48zM416 160h50.7L544 237.3v18.7h-128v-96zM112 416a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm368-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
        data-name="truck-path"
      />
    </svg>
  );
}

export default IconTruck;
