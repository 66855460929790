import React, { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Tooltip
} from '@mui/material';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import InsuranceGradIcon from 'component/atoms/Icons/InsuranceGradIcon';
import { generateRandomId } from 'utils/Utils';
import { styles } from '../../molecules/InsuranceOverview/InsuranceOverviewStyles';
import { useTranslation } from 'react-i18next';
import OverviewDialog from '../../molecules/InsuranceOverview/InsuranceOverviewDialog';
import SearchIcon from '@mui/icons-material/Search';
import AppConfigService from 'entities/AppConfig/AppConfigService';
import { IAppConfig, ITileInfo } from '@ccs-dip/common/types/app-config';
import useInsuranceNotification from 'component/molecules/InsuranceOverview/InsuranceNotifications';
import InsuranceOverviewGrid from '../../molecules/InsuranceOverview/InsuranceOverviewTile';
import { useParams } from 'react-router-dom';
import InsuranceOverviewDataGrid from 'component/molecules/InsuranceOverview/InsuranceOverviewDataGrid';
import ViewCarouselOutlinedIcon from '@mui/icons-material/ViewCarouselOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { useDispatch } from 'react-redux';
import { setOpenBriefView } from 'store/briefview/briefViewDrawerSlice';

const InsuranceOverview = () => {
  const [overviewList, setOverviewList] = useState<ITileInfo[]>([]);
  const [appConfig, setAppConfig] = useState<IAppConfig | null>(null);
  const params = useParams();
  const [isTileView, setIsTileView] = useState(() => {
    const savedView = localStorage.getItem('isTileView');
    return savedView !== null ? JSON.parse(savedView) : true;
  });
  const [gridKey, setGridKey] = useState(0);
  const partynumber = params['partynumber'];
  const [filteredOverviewList, setFilteredOverviewList] = useState<ITileInfo[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [newUrl, setNewUrl] = useState('');
  const [icon, setIcon] = useState('');
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);
  const { t } = useTranslation();
  const { insuranceOverviewNotification } = useInsuranceNotification();
  const dispatch = useDispatch();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); 

  useEffect(() => {
    dispatch(setOpenBriefView(false));
    const fetchOverviewData = async () => {
      const appConfigService = new AppConfigService();
      const response = await appConfigService.get();
      if (Object.keys(response).length) {
        setAppConfig(response);
        setOverviewList(response.tileconfigs);
        setFilteredOverviewList(response.tileconfigs); 
      }
    };
    fetchOverviewData();
  }, [dispatch]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredOverviewList(
      overviewList.filter(
        (item) => item.title.toLowerCase().includes(query) || item.description.toLowerCase().includes(query)
      )
    );
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    setFilteredOverviewList(overviewList);
  };

  const handleAddClick = () => {
    setEditIndex(null);
    setNewTitle('');
    setNewDescription('');
    setNewUrl('');
    setIcon('');
    setDialogOpen(true);
  };

  const handleViewToggle = () => {
    setSearchQuery('');
    setShowSearchBar(false);
    setFilteredOverviewList(overviewList);
    const newView = !isTileView;
    setIsTileView(newView);
    localStorage.setItem('isTileView', JSON.stringify(newView));
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setEditIndex(null);
    setNewTitle('');
    setIcon('');
    setNewDescription('');
    setNewUrl('');
  };

  const saveOverviewData = async (updatedList: ITileInfo[]) => {
    if (!appConfig) return;
    try {
      const appConfigService = new AppConfigService();
      const updatedAppConfig = {
        ...appConfig,
        tileconfigs: updatedList
      };
      await appConfigService.save(updatedAppConfig);
      setAppConfig(updatedAppConfig);
      insuranceOverviewNotification.savedSuccessfully();
    } catch (error) {
      insuranceOverviewNotification.saveFailed();
    }
  };

  const deleteOverviewData = async (key: string) => {
    if (!appConfig) return;

    try {
      const appConfigService = new AppConfigService();
      const updatedList = overviewList.filter((item) => item.key !== key);
      const updatedAppConfig = {
        ...appConfig,
        tileconfigs: updatedList
      };
      await appConfigService.save(updatedAppConfig);
      setOverviewList(updatedList);
      const filteredList = updatedList.filter(
        (item) =>
          item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.description.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredOverviewList(filteredList);
      setGridKey((prevKey) => prevKey + 1);
      insuranceOverviewNotification.savedSuccessfully();
    } catch (error) {
      insuranceOverviewNotification.saveFailed();
    }
  };

  const handleAddOrUpdateOverviewItem = () => {
    if (newTitle && newDescription && newUrl && icon) {
      const updatedList = [...overviewList];
      const newItem = {
        key: editIndex ? updatedList[editIndex]?.key || '' : generateRandomId(),
        title: newTitle,
        description: newDescription,
        url: newUrl,
        icon: icon
      };

      if (editIndex !== null) {
        updatedList[editIndex] = newItem;
      } else {
        updatedList.push(newItem);
      }
      saveOverviewData(updatedList);
      setOverviewList(updatedList);
      const filteredList = updatedList.filter(
        (item) =>
          item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.description.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredOverviewList(filteredList);

      handleDialogClose();
    } else {
      insuranceOverviewNotification.formValidationError();
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    setAnchorEl(event.currentTarget);
    setSelectedItemIndex(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedItemIndex(null);
  };

  const handleEdit = (index: number) => {
    if (selectedItemIndex !== null || index) {
      const item: ITileInfo | undefined = overviewList[selectedItemIndex || index];
      if (item) {
        setNewTitle(item.title);
        setNewDescription(item.description);
        setNewUrl(item.url);
        if (item.icon) setIcon(item?.icon);
      }
      setEditIndex(selectedItemIndex || index);
      setDialogOpen(true);
    }
    handleMenuClose();
  };

  const handleDelete = () => {
    setDeleteDialogOpen(true);
  };

  const confirmDelete = () => {
    if (selectedItemIndex !== null) {
      const selectedItem = overviewList[selectedItemIndex];
      if (selectedItem) deleteOverviewData(selectedItem.key);
    }
    setDeleteDialogOpen(false);
    handleMenuClose();
  };

  const cancelDelete = () => {
    setDeleteDialogOpen(false);
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <InsuranceGradIcon />
        <Typography variant='h6' sx={styles.headerTitle} color='text.primary'>
          {t('policy')}
        </Typography>
        <Box sx={{ ml: 'auto', display: 'flex', gap: 1, alignItems: 'center' }}>
          <Tooltip title={isTileView ? t('gridView') : t('tileView')}>
            <IconButton onClick={handleViewToggle} sx={{ cursor: 'pointer', ml: 1 }} color='secondary'>
              {isTileView ? <GridViewIcon /> : <ViewCarouselOutlinedIcon />}
            </IconButton>
          </Tooltip>

          {isTileView && (
            <Tooltip title='Search'>
              <IconButton onClick={() => setShowSearchBar(!showSearchBar)} sx={{ cursor: 'pointer' }} color='secondary'>
                <SearchIcon />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title='Add New Item'>
            <IconButton onClick={handleAddClick} sx={{ cursor: 'pointer' }} color='primary'>
              <AddCircleOutlinedIcon sx={{ color: (theme: any) => theme.palette.secondary.main }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {showSearchBar && (
        <TextField
          variant='outlined'
          sx={{ p: 2 }}
          fullWidth
          size='small'
          margin='normal'
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={handleClearSearch}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      )}

      <Box sx={{ maxHeight: '550px', overflowY: 'auto' }}>
        {isTileView ? (
          <InsuranceOverviewGrid
            handleAddClick={handleAddClick}
            partynumber={partynumber}
            filteredOverviewList={filteredOverviewList}
            handleMenuOpen={handleMenuOpen}
          />
        ) : (
          <InsuranceOverviewDataGrid
            partynumber={partynumber || ''}
            key={gridKey}
            rows={filteredOverviewList}
            handleEdit={(index: number) => {
              handleEdit(index);
            }}
            handleDelete={(index: number) => {
              setSelectedItemIndex(index);
              setDeleteDialogOpen(true);
            }}
          />
        )}
      </Box>

      <OverviewDialog
        open={dialogOpen}
        title={newTitle}
        description={newDescription}
        url={newUrl}
        icon={icon}
        onClose={handleDialogClose}
        onSave={handleAddOrUpdateOverviewItem}
        onTitleChange={(e) => setNewTitle(e.target.value)}
        onDescriptionChange={(e) => setNewDescription(e.target.value)}
        onUrlChange={(e) => setNewUrl(e.target.value)}
        onIconChange={(e) => setIcon(e.target.value)}
        editMode={editIndex !== null}
      />

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={() => handleEdit(0)}>{t('edit')}</MenuItem>
        <MenuItem onClick={() => handleDelete()}>{t('delete')}</MenuItem>
      </Menu>

      <Dialog open={deleteDialogOpen} onClose={cancelDelete}>
        <DialogTitle>{t('confirmation')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('formDelete')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color='primary'>
            {t('cancel')}
          </Button>
          <Button onClick={confirmDelete} color='secondary'>
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default InsuranceOverview;
