import { useState, useCallback, useMemo, ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

type ConfirmationDialogHook = {
  ConfirmationDialog: ReactNode;
  openDeleteDialog: () => Promise<boolean>;
  openImportDialog: () => Promise<boolean>;
};

const useConfirmationDialog = (): ConfirmationDialogHook => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [resolveDialog, setResolveDialog] = useState<((value: boolean) => void) | null>(null);
  const [dialogContent, setDialogContent] = useState<string>('');
  const [isDialogDelete, setDialogDelete] = useState<boolean>(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(
    (value: boolean) => {
      setOpen(false);
      if (resolveDialog) {
        resolveDialog(value);
        setResolveDialog(null);
      }
    },
    [resolveDialog]
  );

  const openImportDialog = useCallback(() => {
    return new Promise<boolean>((resolve) => {
      setDialogContent(t('formExist'));
      setDialogDelete(false);
      setResolveDialog(() => resolve);
      handleOpen();
    });
  }, [handleOpen, t]);

  const openDeleteDialog = useCallback(() => {
    return new Promise<boolean>((resolve) => {
      setDialogContent(t('formDelete'));
      setDialogDelete(true);
      setResolveDialog(() => resolve);
      handleOpen();
    });
  }, [handleOpen, t]);

  const ConfirmationDialog = useMemo(
    () => (
      <Dialog
        id='AlertDialog'
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{t('Confirmation')}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{dialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>{t('cancel')}</Button>
          <Button onClick={() => handleClose(true)} variant='contained' autoFocus>
            {isDialogDelete ? t('delete') : t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [
      open,
      dialogContent,
      isDialogDelete,
      handleClose,
      t
    ]
  );

  return { ConfirmationDialog, openDeleteDialog, openImportDialog };
};

export default useConfirmationDialog;
