import { useTheme } from '@mui/material/styles';

function IconPlusSquare(props: any) {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '20'}
      height={props.height || '20'}
      viewBox="0 0 576 512"
      aria-labelledby="icon-plus-square-title"
      role="img"
    >
      <title id="icon-plus-square-title">Plus Square Icon</title>
      <defs>
        <linearGradient id="plusSquareGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: theme.palette.primary.main, stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: theme.palette.secondary.main, stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <path fill="none" d="M0 0h576v512H0z" data-name="background" />
      <path
        fill="url(#plusSquareGradient)"
        d="M224 24l0 56-56 0c-13.3 0-24 10.7-24 24l0 48c0 13.3 10.7 24 24 24l56 0 0 56c0 13.3 10.7 24 24 24l48 0c13.3 0 24-10.7 24-24l0-56 56 0c13.3 0 24-10.7 24-24l0-48c0-13.3-10.7-24-24-24l-56 0 0-56c0-13.3-10.7-24-24-24L248 0c-13.3 0-24 10.7-24 24zM559.7 392.2c17.8-13.1 21.6-38.1 8.5-55.9s-38.1-21.6-55.9-8.5L392.6 416 272 416c-8.8 0-16-7.2-16-16s7.2-16 16-16l16 0 64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0-16 0-78.3 0c-29.1 0-57.3 9.9-80 28L68.8 384 32 384c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32l160 0 160.5 0c29 0 57.3-9.3 80.7-26.5l126.6-93.3zm-367-8.2l.9 0c0 0 0 0 0 0c-.3 0-.6 0-.9 0z"
        data-name="plus-square-path"
      />
    </svg>
  );
}

export default IconPlusSquare;
