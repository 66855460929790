import { useSnackBar } from 'context/Snackbar/Snackbar';
import { useTranslation } from 'react-i18next';

const useThemeNotification = () => {
  const { showSnackBar } = useSnackBar();
  const { t } = useTranslation();

  const themeNotification = {
    themeUpdate: () => {
        showSnackBar(t('saveMessage'), 'success');
    },
    themeError: () => {
        showSnackBar(t('saveFailed'), 'error');
    },
    fieldsareRequired: () => {
      showSnackBar(t('requiredField'), 'error');
    },
  };

  return { themeNotification };
};

export default useThemeNotification;
