import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';

const DataGridIcons = {
  TextSnippetIcon,
  DeleteOutlineIcon,
  FileDownloadIcon,
  DriveFileRenameOutlineIcon,
  StyleOutlinedIcon,
  FolderSharedOutlinedIcon,
  AddCircleOutlinedIcon,
  AddCircleOutlineIcon
};
export default DataGridIcons;