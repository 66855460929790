import { GridActionsCellItem, GridActionsCellItemProps, GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { canPerformOperation } from 'api/OperationSupport';

type ActionHandler = (params: GridRowParams) => void;
type OnClickHandler = () => void;
type GetActionsFunction = (params: GridRowParams) => JSX.Element[];

const ACTIONS_NAME = 'actions';

const createEditAction = (handler: OnClickHandler) => (
  <GridActionsCellItem
    icon={<EditIcon />}
    label='Edit'
    onClick={handler}
    showInMenu
    disabled={!canPerformOperation('edit')}
  />
);

const createViewAction = (handler: OnClickHandler) => (
  <GridActionsCellItem
    icon={<ViewIcon />}
    label='View'
    onClick={handler}
    showInMenu
    disabled={!canPerformOperation('view')}
  />
);

const createDeleteAction = (handler: OnClickHandler) => (
  <GridActionsCellItem
    icon={<DeleteIcon />}
    label='Delete'
    onClick={handler}
    showInMenu
    disabled={!canPerformOperation('delete')}
  />
);

const createSeperator = () => <GridActionsCellItem label='' divider showInMenu />;

export const addActionColumn = (
  columns: GridColDef[],
  editActionHandler: (id: string, readonly: boolean) => void,
  deleteActionHandler?: ActionHandler,
  getActions: GetActionsFunction = () => []
) => {
  if (!actionsColumnExits(columns)) {
    const actions_column: GridColDef = {
      field: ACTIONS_NAME,
      type: ACTIONS_NAME,
      width: 80,
      getActions: (params: GridRowParams) => {
        const id = params.id.toString();
        const edit_action = createEditAction(() => editActionHandler(id, false));
        const view_action = createViewAction(() => editActionHandler(id, true));
        const actions = getActions(params);
        const delete_action = deleteActionHandler && createDeleteAction(() => deleteActionHandler(params));

        return [
          edit_action,
          view_action,
          actions.length ? createSeperator() : null,
          ...actions,
          delete_action ? createSeperator() : null,
          delete_action
        ].filter(Boolean) as React.ReactElement<GridActionsCellItemProps>[];
      }
    };

    columns.push(actions_column);
  }
};

export const actionsColumnExits = (columns: GridColDef[]) => {
  return columns.find((column) => column.type === ACTIONS_NAME) !== undefined;
};
