import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import i18n from '../../../config/lang/i18n';
import EntityListView from '../EntityListView/EntityListView';
import { IArchive } from 'entities/Archive/IArchive';
import { useTranslation } from 'react-i18next';
import IconInvoice from 'component/atoms/Icons/IconInvoice';
import IconButton from '@mui/material/IconButton';
import { archiveDownload } from 'utils/Utils';
import { useSnackBar } from 'context/Snackbar/Snackbar';
//===============================================
// private variables
//===============================================

//===============================================
// Component props interface
//===============================================

interface FileListViewProps {
  rows: IArchive[];
}

//===============================================
// Component render function
//===============================================

const FileListView = (props: FileListViewProps) => {
  const { rows } = props;
  const { t } = useTranslation<string>();
  const { showSnackBar } = useSnackBar();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        valueGetter: (_value: string, row: IArchive) => row.id
      },
      {
        field: 'ArchiveNumber',
        headerName: t('archiveNumber'),
        type: 'string',
        flex: 1,
        valueGetter: (_value: string, row: IArchive) => row.ArchiveNumber
      },
      {
        field: 'AttachmentDesc',
        headerName: t('attachmentDesc'),
        type: 'string',
        flex: 2,
        valueGetter: (_value: string, row: IArchive) => row.FileAttachmentInfo.AttachmentDesc
      },
      {
        field: 'AttachmentType',
        headerName: t('documentType'),
        type: 'string',
        flex: 1,
        valueGetter: (_value: string, row: IArchive) => row.FileAttachmentInfo.MIMEContentTypeCd.Value,
        renderCell: (params) => (
          <>
            <IconButton
              style={{ paddingRight: '10px', display: 'inline-block' }}
              aria-label='Download'
              onClick={() => archiveDownload(params.row.ArchiveNumber, showSnackBar)}>
              <IconInvoice></IconInvoice>
            </IconButton>
            {params.value}
          </>
        )
      },
      {
        field: 'CreationDt',
        headerName: t('CreationDt'),
        type: 'string',
        flex: 1,
        valueGetter: (_value: string, row: IArchive) => row.FileAttachmentInfo.CreationDt
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language]
  );

  return (
    <EntityListView
      id='FileListView'
      columns={columns}
      rows={rows}
      getId={(row) => row.ArchiveNumber}
      briefViewType='ArchiveBriefView'
    />
  );
};

export default FileListView;
