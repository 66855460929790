export class Queue {
    items = [];
    push(item) {
        this.items.push(item);
    }
    remove() {
        return this.items.shift();
    }
    peek() {
        return this.items[0];
    }
    isEmpty() {
        return this.items.length === 0;
    }
    size() {
        return this.items.length;
    }
}
