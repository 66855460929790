import { useTheme } from '@mui/material/styles';

function IconTruckTransport(props: any) {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '20'}
      height={props.height || '20'}
      viewBox="0 0 640 512"
      aria-labelledby="icon-truck-transport-title"
      role="img"
    >
      <title id="icon-truck-transport-title">Truck Transport Icon</title>
      <defs>
        <linearGradient id="transportGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: theme.palette.primary.main, stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: theme.palette.secondary.main, stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <path fill="none" d="M0 0h640v512H0z" data-name="background" />
      <path
        fill="url(#transportGradient)"
        d="M64 32C28.7 32 0 60.7 0 96V304v80v16c0 44.2 35.8 80 80 80c26.2 0 49.4-12.6 64-32c14.6 19.4 37.8 32 64 32c44.2 0 80-35.8 80-80c0-5.5-.6-10.8-1.6-16H416h33.6c-1 5.2-1.6 10.5-1.6 16c0 44.2 35.8 80 80 80s80-35.8 80-80c0-5.5-.6-10.8-1.6-16h1.6c17.7 0 32-14.3 32-32v-64v-16v-10.3c0-9.2-3.2-18.2-9-25.3l-58.8-71.8c-10.6-13-26.5-20.5-43.3-20.5H480v-48c0-35.3-28.7-64-64-64H64zM585 256h-105v-64h48.8c2.4 0 4.7 1.1 6.2 2.9L585 256zM528 368a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM176 400a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM80 368a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
        data-name="truck-transport-path"
      />
    </svg>
  );
}

export default IconTruckTransport;
