import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import EntityListView from '../EntityListView/EntityListView';
import { Box, Chip, alpha } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from 'config/lang/i18n';
import { Billing } from 'entities/Billing/IBilling';
import { currencyFormat } from 'utils/Constants';

interface IBillingListViewProps {
  rows: Billing[];
}

const BillingListView = (props: IBillingListViewProps) => {
  const { t } = useTranslation<string>();
  const { rows } = props;

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        renderCell: (params) => <>{params.row.id}</>
      },
      {
        field: 'Billingnumber',
        headerName: t('billingnumber'),
        type: 'string',
        flex: 1,
        renderCell: (params) => <>{params.row.BillingNumber}</>
      },
      {
        field: 'BillingActivityDesc',
        headerName: t('BillingActivityDesc'),
        type: 'string',
        flex: 1,
        renderCell: (params) => <>{params.row.BillingInfo.BillingActivityInfo.BillingActivityDesc}</>
      },
      {
        field: 'Reference',
        headerName: t('reference'),
        type: 'string',
        flex: 2,
        valueGetter: (_value: string, row: Billing) => row.BillingInfo.BillingDesc
      },
      {
        field: 'Total amount',
        headerName: t('totalamount'),
        type: 'string',
        flex: 1,
        valueGetter: (_value: string, row: Billing) => row.BillingInfo.InstallmentInfo.InstallmentAmt.Amt,
        renderCell: (params) => (
          <>
            <span>{currencyFormat[i18n.language]}</span>
            <span>{params.row.BillingInfo.InstallmentInfo.InstallmentAmt.Amt}</span>
          </>
        )
      },
      {
        field: 'status',
        headerName: t('status'),
        type: 'string',
        flex: 1,
        renderCell: (params) => (
          <>
            <Box sx={{ pr: 2 }}>
              <Chip
                sx={{
                  background: (theme) => alpha(theme.palette.secondary.main, 0.15),
                  color: (theme) => theme.palette.secondary.main
                }}
                color='secondary'
                variant='filled'
                label={params.row.BillingInfo.BillingActivityInfo.StatusDesc.replace('EN_', '')}
              />
            </Box>
          </>
        )
      },
      {
        field: 'Date',
        headerName: t('noteDt'),
        type: 'string',
        flex: 1,
        valueGetter: (_value: string, row: Billing) => row.BillingInfo.NoteDt
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language]
  );

  return (
    <EntityListView
      id='BillingListView'
      columns={columns}
      rows={rows}
      getId={(row) => {
        return row?.BillingNumber;
      }}
      briefViewType='BillingBriefView'
    />
  );
};

export default BillingListView;
