import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { ThemeConfirmationDialog } from './ThemeConfirmationDialog';
import { SettingsDialog } from './SettingsDialog';
import AppConfigService from 'entities/AppConfig/AppConfigService';
import { IAppConfig, IThemeConfig } from '@ccs-dip/common/types/app-config';
import { useDispatch, useSelector } from 'react-redux';
import { resetTheme, setCurrentTheme, setViewComponent,setViewPane } from 'store/theme/themeSlice';
import { ThemeCard } from './ThemeCard';
import { AddThemeCard } from './ThemeAddCard';
import { DeleteConfirmationDialog } from './ThemeDeleteDialog';
import useThemeNotification from './useThemeNotification';
import { State } from 'store/store';
import ComponentSwitch from './UISwitch';

export const Settings: React.FC = () => {
  const dispatch = useDispatch();

  const [appConfig, setAppConfig] = useState<IAppConfig | null>(null);

  const currentTheme = useSelector((state: any) => state.themeConfig.currentTheme);
  const [openDialog, setOpenDialog] = useState(false);
  const [themeMode, setMode] = useState(currentTheme?.isDark);
  const [openPane,setOpenPane ] = useState(true);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [themeToApply, setThemeToApply] = useState<IThemeConfig | null>(currentTheme);
  const [themeReadyToApply, setThemeReadyToApply] = useState<IThemeConfig | null>(currentTheme);
  const [themeToEdit, setThemeToEdit] = useState<IThemeConfig | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [themeToDelete, setThemeToDelete] = useState<IThemeConfig | null>(null);
  const { themeNotification } = useThemeNotification();
  const viewComponent = useSelector((state: State) => state?.themeConfig?.viewComponent);
  const defaultTheme = useSelector((state: State) => state?.themeConfig?.defaultTheme);

  const darkTheme = useSelector((state: State) => state?.themeConfig?.darkTheme);

  useEffect(() => {
    const appConfigService = new AppConfigService();

    appConfigService.get().then((response: IAppConfig) => {
      setAppConfig(response);

      const selectedTheme = response.themeconfigs?.find((itm: IThemeConfig) => itm.isSelected);
      if (selectedTheme) {
        const combinedTheme: IThemeConfig = { ...selectedTheme, theme: { ...selectedTheme.theme } };

        if (themeMode) {
          combinedTheme.theme = {
            ...darkTheme.theme,
            palette: {
              ...darkTheme.theme.palette,
              primary: {
                ...darkTheme.theme.palette.primary,
                main: selectedTheme.theme.palette.primary.main 
              },
              secondary: {
                ...darkTheme.theme.palette.secondary,
                main: selectedTheme.theme.palette.secondary.main 
              }
            }
          };
        }

        combinedTheme.isDark = themeMode;
        dispatch(setCurrentTheme(combinedTheme));
        setThemeToApply((prevTheme) => ({
          ...prevTheme,
          ...combinedTheme 
        }));

        setThemeToEdit((prevTheme) => ({
          ...prevTheme,
          ...selectedTheme 
        }));
        selectedTheme.isDark = themeMode;
        handleSaveModeTheme(selectedTheme);
      } else {
        setThemeToApply(defaultTheme);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeMode]);

  useEffect(() => {
    setMode(currentTheme.isDark ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTheme?.isDark]);

  useEffect(() => {
    setThemeToApply(currentTheme);
  }, [currentTheme]);

  const handleSelectTheme = (config: IThemeConfig) => {
    setThemeReadyToApply(config);
    setOpenConfirmDialog(true);
  };

  const confirmApplyTheme = () => {
    const updatedThemes = appConfig?.themeconfigs.map((theme) => {
      if (theme) {
        const curTheme = { ...theme };
        curTheme.isSelected = theme.key === themeReadyToApply?.key;
        return curTheme;
      } else {
        return theme;
      }
    });
    if (themeReadyToApply && updatedThemes) {
      dispatch(setCurrentTheme(themeReadyToApply));
      setOpenConfirmDialog(false);
      setAppConfig({ ...appConfig!, themeconfigs: updatedThemes });
      saveTheme({ ...appConfig!, themeconfigs: updatedThemes });
      setThemeToApply(themeReadyToApply);
    }
  };

  const handleEditTheme = (config: IThemeConfig) => {
    setThemeToEdit(config);
    setOpenDialog(true);
  };

  const handleDeleteTheme = (config: IThemeConfig) => {
    if (themeToApply?.key === config.key) {
      dispatch(resetTheme());
    }
    const updatedThemes = appConfig?.themeconfigs.filter((theme) => theme.key !== config.key);
    if (updatedThemes) {
      setAppConfig({ ...appConfig!, themeconfigs: updatedThemes });
      saveTheme({ ...appConfig!, themeconfigs: updatedThemes });
    }
  };

  const handleOpenDeleteDialog = (theme: IThemeConfig) => {
    setThemeToDelete(theme);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setThemeToDelete(null);
  };

  const handleConfirmDelete = () => {
    if (themeToDelete) {
      if (themeToDelete.isSelected) {
        const theme = appConfig?.themeconfigs.find(
          (theme) => theme?.theme?.palette?.primary?.main === defaultTheme?.theme?.palette?.primary?.main
        );
        if (theme) {
          setThemeToApply(theme);
          dispatch(setCurrentTheme(theme));
        }
      }
      handleDeleteTheme(themeToDelete);
    }
    handleCloseDeleteDialog();
  };

  const saveTheme = async (updatedAppConfig: IAppConfig | null) => {
    if (!updatedAppConfig) return;
    try {
      const appConfigService = new AppConfigService();
      await appConfigService.save(updatedAppConfig);
      themeNotification.themeUpdate();
    } catch (error) {
      themeNotification.themeError();
    }
  };
  const handleSaveModeTheme = (theme: IThemeConfig) => {
    const updatedThemes = theme
      ? appConfig?.themeconfigs.map((t) => (t.key === theme.key ? theme : t))
      : [...(appConfig?.themeconfigs || []), theme];
    if (updatedThemes) {
      setAppConfig({ ...appConfig!, themeconfigs: updatedThemes, drawerConfig:{
        menuList:[],
        isOpen:openPane
      } });
      saveTheme({ ...appConfig!, themeconfigs: updatedThemes, drawerConfig:{
        menuList:[],
        isOpen:openPane
      } });
    }
  };
  const handleSavePane = () => {
    saveTheme({ ...appConfig!, drawerConfig:{
      menuList:[],
      isOpen:openPane
    }});
  };

  const handleSaveTheme = (theme: IThemeConfig) => {
    const updatedThemes = themeToEdit
      ? appConfig?.themeconfigs.map((t) => (t.key === themeToEdit.key ? theme : t))
      : [...(appConfig?.themeconfigs || []), theme];
    if (updatedThemes) {
      setAppConfig({ ...appConfig!, themeconfigs: updatedThemes, drawerConfig:{
        menuList:[],
        isOpen:openPane
      }});
      saveTheme({ ...appConfig!, themeconfigs: updatedThemes, drawerConfig:{
        menuList:[],
        isOpen:openPane
      }});

      if (theme?.isSelected) {
        dispatch(setCurrentTheme(theme));
      }
    }
    setOpenDialog(false);
  };

  return (
    <Box sx={{ p: 4, position: 'relative', zIndex: 1000 }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '16px' }}>
        {' '}
        {/* Add padding as needed */}
        <ComponentSwitch
          changeHandler={() => {
            setMode(!themeMode);
          }}
          viewComponent={themeMode}
        />
        <Box sx={{display:'none'}}>
         <ComponentSwitch
          changeHandler={() => {
            setOpenPane(!openPane);
            dispatch(setViewPane());
            handleSavePane();
          }}
          leftLabel={'Hide'}
          rightLabel={'View'}
          viewComponent={openPane}
        />
        </Box>
        <ComponentSwitch
          changeHandler={() => {
            dispatch(setViewComponent());
          }}
          leftLabel={'Hide'}
          rightLabel={'View'}
          viewComponent={viewComponent}
        />
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        {appConfig?.themeconfigs?.map((config: IThemeConfig) => (
          <ThemeCard
            key={config.key}
            config={config}
            selectedTheme={themeToApply?.key}
            onSelect={handleSelectTheme}
            onEdit={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              handleEditTheme(config);
            }}
            onDelete={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              handleOpenDeleteDialog(config);
            }}
          />
        ))}
        <AddThemeCard
          selectedTheme={themeToApply?.key}
          onAddTheme={() => {
            setOpenDialog(true);
            setThemeToEdit(null);
          }}
        />
      </Box>

      <SettingsDialog
        open={openDialog}
        key={themeToEdit?.key}
        themeToEdit={themeToEdit}
        onSave={handleSaveTheme}
        onClose={() => {
          setOpenDialog(false);
          setThemeToEdit(null);
        }}
      />

      <ThemeConfirmationDialog
        open={openConfirmDialog}
        themeToApply={themeToApply}
        onConfirm={confirmApplyTheme}
        onCancel={() => setOpenConfirmDialog(false)}
      />

      <DeleteConfirmationDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onConfirmDelete={handleConfirmDelete}
      />
    </Box>
  );
};
