import { Form } from 'formiojs';
import { IForm } from '@ccs-dip/common/types/formio-types';
import FormioService from 'entities/Formio/FormioService';
import { formioEventHandlerType } from '../attachEventHandlerToFormioInstance';
import { getCustomProperty, getFieldValue, getValueBySubPath, searchComponents } from './helperFunctions';

//===============================================
// private variables
//===============================================
const formio_service = new FormioService();

const getValueForFormKey = (fieldNames: string[], submission: any) => {
  const values: string[] = [];
  for (const fieldName of fieldNames) {
    const value = getValueBySubPath(submission, fieldName).toString();
    if (value === '') return value;
    values.push(value);
  }
  return values.join('-');
};

const createWizardInstance = (instance: any) => {
  const wizardInstance = new Form().create('wizard');
  // copying over the previous instance object's values.
  Object.keys(instance).forEach((key) => {
    wizardInstance[key] = instance[key];
  });
  return wizardInstance;
};

const replaceFormByForm = (instance: any, form: IForm) => {
  if (form.display === 'wizard') {
    instance.subForm = createWizardInstance(instance.subForm);
  }
  instance.subForm.form = form;
  const value = instance.getValue();
  value.metadata = { key: form.key };
  instance.setValue(value);
  if (form.display === 'wizard') {
    // to trigger custom default value evaluation for container components
    const condition = (component: any) =>
      component.type === 'container' && component.getCustomDefaultValue('') !== undefined;
    searchComponents(instance.subForm, condition);
  }
};

const replaceFormByKey = (instance: any, key: string) => {
  if (key === '') {
    replaceFormByForm(instance, {} as IForm);
  } else if (instance.subForm.form.key !== key) {
    formio_service
      .get(key)
      .then((form) => replaceFormByForm(instance, form))
      .catch((_reason) => {});
  }
};

const replaceFormByValue = (instance: any, value: string) => {
  const prevKey = `prev-${instance.key}`;
  const nestedFormValue = instance.dataValue;

  const subForm = instance.subForm;
  const isReplaced =
    nestedFormValue.data[prevKey] !== value || (subForm && subForm.form.key !== nestedFormValue?.metadata?.key);

  if (subForm && isReplaced) {
    nestedFormValue.data[prevKey] = value;
    formio_service
      .getKey(getFieldValue(instance, value))
      .then((key) => replaceFormByKey(instance, key ?? ''))
      .catch((_reason) => {});
  }
};

//==============================================================
// public variables
//==============================================================

export const replaceFormChangeEventHandler: formioEventHandlerType = (eventData, instance, _emitEventName) => {
  if (!instance.subForm) return;

  const changed = eventData.changed?.instance;
  const replace_form = getCustomProperty(instance.component, 'replace_form');
  const fieldNames = replace_form.split(/[\s,;]+/);
  const fieldName = fieldNames.shift() ?? '';
  let fieldValue: string = changed && changed.key === fieldName ? changed.dataValue : '';

  if (fieldName !== '') {
    if (fieldName.includes('.')) {
      const value = getValueBySubPath(instance.root.submission, fieldName);
      fieldValue = typeof value === 'string' ? value : '';
    } else {
      const filterInstance = searchComponents(instance.root, (current) => current.key === fieldName)[0];
      if (filterInstance && filterInstance.dataValue === '') {
        const value = getValueForFormKey(fieldNames, instance.root.submission);
        filterInstance.setValue(value);
      }
      fieldValue = filterInstance.dataValue;
    }
  }

  if (fieldValue !== '') {
    replaceFormByValue(instance, fieldValue);
  }
};
