import { searchComponents } from 'utils/formio/CustomPropertySupport/helperFunctions';
import { AlertColor } from '@mui/material';
import { i18n } from 'i18next';

//===============================================
// types
//===============================================

type OnCustomEventFunction = (
  eventData: any,
  showSnackBar: (text: string, typeColor: AlertColor) => void,
  i18n: i18n
) => void;

//===============================================
// private variables
//===============================================

const focusOnComponent = (root: any, instance: any) => {
  if (root.id === instance.id) {
    return;
  }
  focusOnComponent(root, instance.parent);
  instance.focus();
};

const getFirstInstanceWithError = (instance: any) => {
  if (instance) {
    const condition = (current: any) => !current.checkValidity(null, false, null, true);
    return searchComponents(instance, condition)[0];
  }
  return undefined;
};

//===============================================
// public variables
//===============================================

export const onCustomEvent: OnCustomEventFunction = (eventData, showSnackBar, i18n) => {
  const type: string = eventData.type ?? '';
  switch (type) {
    case 'success': {
      const operation: string = eventData.component.properties.operation ?? '';
      showSnackBar(i18n.t(`success.${operation}`), 'success');
      break;
    }
    case 'error': {
      const instance = eventData.instance;
      const invalidInstance = getFirstInstanceWithError(instance);
      if (invalidInstance) {
        focusOnComponent(instance, invalidInstance);
        // TODO: Find a better alternative to trigger error
        // since the code needs to be executed after the button custom action handler.
        // Note: after focusing the error disappears if the component is nested inside tabs component
        setTimeout(() => {
          instance.checkValidity(null, true, null, false);
        }, 500);
      }
      break;
    }
  }
};
