import { JSONObject } from '@ccs-dip/common/json/types';
import { isJsonObject } from '@ccs-dip/common/json/utils';
import FormioService from 'entities/Formio/FormioService';
import { getRowComponentForDynamicContainer } from '../generateComponentJson';
import { attachEventHandler, formioEventHandlerType } from '../attachEventHandlerToFormioInstance';
import {
  getCustomProperty,
  getFieldValue,
  getNewInstance,
  getValueBySubPath,
  redrawAndRestore
} from './helperFunctions';

//===============================================
// Types
//===============================================

interface ISortedFormData {
  key: string;
  value: JSONObject;
}

//===============================================
// private variables
//===============================================

const getIndexByKey = (key: string) => {
  return +key.substring(key.lastIndexOf('_') + 1);
};

// To get the keys in a sorted order
const getFormDataObjects = (value: JSONObject) => {
  const result: ISortedFormData[] = [];
  Object.keys(value).forEach((key) => {
    const current_value = value[key];
    if (current_value && isJsonObject(current_value) && current_value['data'] !== undefined) {
      result[getIndexByKey(key)] = { key, value: current_value };
    }
  });
  return result;
};

// This is used while removing a row, because new row indices should be allocated.
const updateRowsIndices = (instance: any) => {
  const components: Array<any> = instance.components ?? [];
  components.forEach((columnsComponent, index) => {
    columnsComponent.component.key = `columns_${index}`;
    columnsComponent.components.forEach((component: any) => {
      const type: string = component.type;
      component.component.key = type === 'form' ? `${component.component.form}_${index}` : `remove_${index}`;
    });
  });
};

// This is used while removing a row, since new keys will be allocated
const updateContainerValue = (instance: any, toRemoveKey: string) => {
  const toRemoveIndex = getIndexByKey(toRemoveKey);
  const oldValue: JSONObject = instance.dataValue;
  const newValue: JSONObject = {};

  const formDataObjects: ISortedFormData[] = getFormDataObjects(oldValue);

  let index = 0;
  formDataObjects.forEach((formData) => {
    if (getIndexByKey(formData.key) !== toRemoveIndex) {
      const key = `${formData.key.substring(0, formData.key.lastIndexOf('_'))}_${index++}`;
      newValue[key] = JSON.parse(JSON.stringify(formData.value));
    }
  });

  instance.dataValue = newValue;
};

//===============================================
// public variables
//===============================================

export class DynamicContainer {
  private addByKey(instance: any, key: string) {
    instance.addComponent(getRowComponentForDynamicContainer(key, instance.page ?? 0, instance.components.length));
    redrawAndRestore(instance);
  }

  private addByValue(instance: any, value: string) {
    const isFormKey = /_[0-9]*$/.test(value); // here the value may contain form-key_{row}
    if (isFormKey) {
      this.addByKey(instance, value.substring(0, value.lastIndexOf('_')));
    } else {
      const formio_service = new FormioService();
      formio_service
        .getKey(getFieldValue(instance, value))
        .then((key) => (key ? this.addByKey(instance, key) : undefined))
        .catch((_reason) => {});
    }
  }

  private removeByKey(instance: any, columnsKey: string) {
    instance.removeComponentByKey(columnsKey);
    updateRowsIndices(instance);
    updateContainerValue(instance, columnsKey);
    redrawAndRestore(instance);
  }

  private emitAddEvent(instance: any, emitEventName: string) {
    instance.root.emit(emitEventName);
  }

  private emitRemoveEvent(instance: any) {
    // remove button's parent component, that is columns component and parent of it is container component
    const fieldName: string = getCustomProperty(instance.parent.parent.component, 'dynamic_container');
    instance.root.emit(`dc-${fieldName}`, { value: instance.parent.key, toRemove: true });
  }

  private addEventHandler: formioEventHandlerType = (_eventData, instance, emitEventName) => {
    instance.root.emit(emitEventName, { value: instance.dataValue, toRemove: false });
  };

  private dcEventHandler: formioEventHandlerType = (eventData, instance, _emitEventName) => {
    if (eventData && typeof eventData === 'object') {
      instance = getNewInstance(instance);
      const value: string = eventData.value ?? '';
      const toRemove: boolean = eventData.toRemove ?? false;
      toRemove ? this.removeByKey(instance, value) : this.addByValue(instance, value);
    }
  };

  private changeHandler: formioEventHandlerType = (_eventData, instance, _emitEventName) => {
    instance = getNewInstance(instance);
    const value = instance.dataValue;
    const componentsPresent = Object.keys(value).filter((v) => typeof value[v] === 'object').length;

    if (instance.components.length !== componentsPresent) {
      const formDataObjects = getFormDataObjects(value);
      formDataObjects.forEach(
        (dataObject) => !instance.getComponent(dataObject.key) && this.addByValue(instance, dataObject.key)
      );
    }
  };

  /* Custom Action for buttons */
  public addButtonCustomAction(instance: any, emitEventName: string) {
    this.emitAddEvent(instance, emitEventName);
  }

  public removeButtonCustomAction(instance: any) {
    this.emitRemoveEvent(instance);
  }

  /* Custom Default Value for fields */
  public fieldCustomDefaultValue(instance: any, eventName: string, emitEventName: string) {
    attachEventHandler(instance, eventName, emitEventName, this.addEventHandler);
    return instance.dataValue ? instance.dataValue : instance.component.defaultValue;
  }

  public containerCustomDefaultValue(instance: any, submission: any, eventName: string) {
    attachEventHandler(instance, eventName, '', this.dcEventHandler);
    attachEventHandler(instance, 'change', '', this.changeHandler);
    const value = getValueBySubPath(submission, instance.key);
    return value ? value : {};
  }
}
