import { formioEventHandlerType } from '../attachEventHandlerToFormioInstance';
import { getNewInstance, redrawAndRestore } from './helperFunctions';
import { SummaryPanelBuilder } from './SummaryPanelBuilder';

//===============================================
// private variables
//===============================================

// Handler for Wizard
const renderSummaryForWizard = (instance: any) => {
  // Checking if the current page is Summary
  if (instance.root.page === instance.page) {
    if (instance.components) {
      instance.destroyComponents();
    }

    const visited_pages: any[] = instance.root.pages ? instance.root.pages.slice(0, instance.page) : [];
    for (const page of visited_pages) {
      const builder = new SummaryPanelBuilder(page.component.title, `summary-${page.key}`);
      const panel = builder.getSummary(page);
      if (panel) {
        instance.addComponent(panel);
      }
    }

    redrawAndRestore(instance);
  }
};

//===============================================
// public variables
//===============================================

export const summaryRenderEventHandler: formioEventHandlerType = (_eventData, instance, _emitEventName) => {
  const type = instance.root.form.display;

  if (type === 'wizard') {
    instance = getNewInstance(instance);
    renderSummaryForWizard(instance);
  }
};
