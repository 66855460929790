import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import {
  GridRowModes,
  DataGridPro,
  GridColDef,
  GridActionsCellItem,
  GridRowParams,
  GridRowModesModel
} from '@mui/x-data-grid-pro';

import { LanguageCode } from 'entities/Translation/TranslationService';
import { TranslationRow } from './TranslationRowMap';
import useTranslationData from './useTranslationData';
import useTranslationEvents from './useTranslationEvents';
import { useTranslation } from 'react-i18next';
import CustomDataGridToolbar from 'utils/CustomDataGridToolbar';
import { Box, Button } from '@mui/material';
import { formButtonWrap, toolBar, toolbarWrap } from 'component/molecules/Formrenderer/FormGridStyledComponent';
import UploadFormJson from 'component/molecules/Formrenderer/FormJsonUpload';
import { FormButtonProps } from 'component/molecules/Formrenderer/IFormDataGrid';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { generateRandomId } from 'utils/Utils';
import ImportDialog from './TranslationImportDialog';
import { CustomPagination, TranslationDataGridProSx } from './CustomPagination';
import { useState } from 'react';

//===============================================
// local variables
//===============================================

const translationValueGetter = (row: TranslationRow, lang: LanguageCode) => {
  const data = row[lang];
  return hasCustomValue(row, lang) ? data?.['custom'] : data?.['default'];
};

const translationValueSetter = (value: string, row: TranslationRow, lang: LanguageCode) => {
  if (row) {
    const data = row[lang] ?? (row[lang] = {});
    const isDefault = value === data['default'];

    if (isDefault) {
      delete data['custom'];
    } else {
      data['custom'] = value;
    }
  }

  return { ...row };
};

const typeValueGetter = (row: TranslationRow, rowModesModel: GridRowModesModel) => {
  const isEdit = rowModesModel[row.key]?.mode === GridRowModes.Edit;
  const isCustom = hasCustomValue(row, 'en', 'nl');
  return isEdit ? 'Editing' : isCustom ? 'Custom' : 'Default';
};

const hasCustomValue = (row: TranslationRow, ...languages: LanguageCode[]) => {
  return languages.some((lang) => {
    const data = row[lang] ?? {};
    return data['custom'] !== undefined;
  });
};

//===============================================
// Component render function
//===============================================

export default function TranslationDataGrid() {
  const { rows, api } = useTranslationData();
  const { rowModesModel, events, open, overrideOption } = useTranslationEvents(rows, api);
  const { t } = useTranslation();

  const GridButton: React.FC<FormButtonProps> = ({ onClick, icon, label }) => (
    // eslint-disable-next-line react/jsx-no-undef
    <Button variant='contained' sx={formButtonWrap} onClick={onClick}>
      {icon}
      {label}
    </Button>
  );

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0
  });

  const addNewRow = () => {
    setPaginationModel((prevModel) => ({ ...prevModel, page: 0 }));
    const id = generateRandomId();
    api.addRow(id);
    const onAdd = events.onAdder(id);
    onAdd();
  };

  const handlePaginationModelChange = (model: any) => {
    setPaginationModel(model);
  };

  const columns: GridColDef[] = [
    {
      field: 'key',
      headerName: t('Key'),
      flex: 3,
      editable: true
    },
    {
      field: 'type',
      headerName: t('Type'),
      flex: 1,
      valueGetter: (_value, row) => typeValueGetter(row, rowModesModel)
    },
    {
      field: 'en',
      headerName: t('English'),
      flex: 4,
      editable: true,
      valueGetter: (_value, row) => translationValueGetter(row, 'en'),
      valueSetter: (value, row) => translationValueSetter(value, row, 'en')
    },
    {
      field: 'nl',
      headerName: t('Dutch'),
      flex: 4,
      editable: true,
      valueGetter: (_value, row) => translationValueGetter(row, 'nl'),
      valueSetter: (value, row) => translationValueSetter(value, row, 'nl')
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t('Actions'),
      flex: 2,
      cellClassName: 'actions',
      getActions: (params: GridRowParams<TranslationRow>) => {
        const { id, row } = params;
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const isDefault = !hasCustomValue(row, 'en', 'nl');

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label='Save'
              sx={{
                color: 'primary.main'
              }}
              onClick={events.onSave(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label='Cancel'
              className='textPrimary'
              onClick={events.onCancel(id)}
              color='inherit'
            />

          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label='Edit'
            className='textPrimary'
            onClick={events.onEdit(id)}
            color='inherit'
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label='Delete'
            onClick={events.onDelete(id)}
            color='inherit'
            disabled={isDefault}
          />
        ];
      }
    }
  ];

  return (
    <Box sx={{ height: 'calc(100vh - 175px)', width: '100%' }}>
      <Box sx={{ height: '100%', width: '100%' }} id='translationGrid'>
        <DataGridPro
          sx={TranslationDataGridProSx}
          rows={rows}
          columns={columns}
          editMode='row'
          getRowId={(row: any) => row.key || row.id}
          rowModesModel={rowModesModel}
          columnHeaderHeight={36}
          isCellEditable={(params) => params.field !== 'key' || params.row.nl?.default === undefined}
          onRowModesModelChange={events.onRowModesModelChange}
          onRowEditStop={events.onRowEditStop}
          processRowUpdate={events.processRowUpdate}
          disableColumnPinning
          pagination
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          initialState={{
            sorting: {
              sortModel: [{ field: 'key', sort: 'asc' }]
            },
            pagination: { paginationModel: { pageSize: 100 } }
          }}
          slots={{
            toolbar: CustomDataGridToolbar,
            pagination: CustomPagination
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              title: t('translationdatagrid')
            }
          }}
        />
      </Box>
      <Box sx={toolBar}>
        <Box sx={toolbarWrap}>
          <UploadFormJson handleFileUpload={events.onFileUpload} />
          <GridButton
            onClick={() => events.onExportAsJson()}
            icon={<CloudDownloadIcon sx={{ fontSize: '20px', mr: 1 }}></CloudDownloadIcon>}
            label={t('export')}
          />
          <GridButton
            onClick={() => {
              addNewRow();
            }}
            icon={<AddCircleOutlineIcon sx={{ fontSize: '20px', mr: 1 }}></AddCircleOutlineIcon>}
            label={t('addRecord')}
          />
        </Box>
        <ImportDialog
          open={open}
          onClose={events.onDialogClose}
          overrideOption={overrideOption}
          onOverrideChange={events.onOverrideChange}
        />
      </Box>
    </Box>
  );
}
